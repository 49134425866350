import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404 Nem található" />
    <h1>404 a kért oldal nem található.</h1>
    <p>Vissza a <Link to={'/'}>kezdőlapra</Link>.</p>
  </Layout>
)

export default NotFoundPage
